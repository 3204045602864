<template>
  <Nav />
  <router-view />
</template>

<script>
import "./assets/css/style.scss";
import "./assets/css/custom.scss";
import Nav from "@/components/Common/Nav.vue";
// import Footer from "@/components/Common/Footer.vue";

export default {
  name: "App",
  components: { Nav },
};
</script>

<style lang="scss"></style>
