<template>
  <nav
    :class="`flex justify-between py-md items-center ${isOpen ? 'open' : ''}`"
  >
    <div class="overlay" @click="isOpen = false"></div>
    <div class="logo-area">
      <router-link :to="{ path: '/' }">
        <div class="logo text-primary">
          <img src="../../assets/images/logo.svg" alt="" />
        </div>
      </router-link>
    </div>
    <div class="nav-items ml-xl flex items-center">
      <router-link class="nav-link" active-class="active" :to="{ path: '/' }">
        <div class="nav-item" @click="isOpen = !isOpen">
          {{ $t("Nav.Home") }}
        </div>
      </router-link>
      <router-link
        class="nav-link"
        active-class="active"
        :to="{ path: '/about' }"
      >
        <div class="nav-item px-xs ml-xl" @click="isOpen = !isOpen">
          {{ $t("Nav.About") }}
        </div>
      </router-link>
      <router-link
        class="nav-link"
        active-class="active"
        :to="{ path: '/services' }"
      >
        <div class="nav-item px-xs ml-xl" @click="isOpen = !isOpen">
          {{ $t("Nav.Services") }}
        </div>
      </router-link>
      <router-link
        class="nav-link"
        active-class="active"
        :to="{ path: '/contact' }"
      >
        <div class="nav-item px-xs ml-xl" @click="isOpen = !isOpen">
          {{ $t("Nav.Contact") }}
        </div>
      </router-link>
      <div class="pointer nav-item flag px-xs ml-xl">
        <!-- <img
          @click="changeLocale('en')"
          src="../../assets/images/united-states.png"
          alt=""
        /> -->
        <p
          v-bind:class="[currentLang !== 'ja' ? 'highlight-lang' : '']"
          @click="changeLocale('en')"
        >
          EN
        </p>
      </div>
      /
      <div class="pointer nav-item flag px-xs">
        <!-- <img
          @click="changeLocale('ja')"
          src="../../assets/images/japan.png"
          alt=""
        /> -->
        <p
          v-bind:class="[currentLang === 'ja' ? 'highlight-lang' : '']"
          @click="changeLocale('ja')"
        >
          JP
        </p>
      </div>
    </div>

    <div class="menu-bar pointer">
      <i class="material-icons" @click="isOpen = !isOpen">menu</i>
    </div>
  </nav>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      isOpen: false,
      currentLang: this.$root.$i18n.locale,
    };
  },
  computed: {
    ...mapState(["locale"]),
    activeLocale() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    ...mapMutations(["setLocale"]),
    changeLocale(locale) {
      this.setLocale({ locale });
      this.$root.$i18n.locale = locale;
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss">
.nav-items {
  font-weight: 600;
  .nav-link {
  }
  .nav-item {
  }
}
.logo {
  img {
    height: 65px;
    width: 65px;
    object-fit: contain;
    object-position: center;
  }
}
.menu-bar,
.overlay {
  display: none;
}
@media (max-width: 768px) {
  .flag {
    transition: 0.2s ease-in;
    margin-left: 0;
    padding: 8px 0;
    img {
      height: 36px;
      border: 1px solid #ccc;
      border-radius: 100px;
    }
    &:hover {
      transform: scale(1.2, 1.2);
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
  }

  .open {
    .overlay {
      display: block;
    }

    .nav-items {
      animation: slideInLeft 0.3s ease-in;
      position: fixed;
      height: 100vh;
      width: 70vw;
      background: white;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      padding: 8vh 32px;
      z-index: 100;
      align-items: flex-start;
      a {
        .nav-item {
          font-size: 24px;
          padding: 8px 0;
          margin: 0;
        }
      }
      //color: white;
    }
  }
  .menu-bar {
    display: block;
  }
  .nav-items {
    display: none;
  }
}

.language-selector {
  span {
    padding: 10px;
    cursor: pointer;
  }
}
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
.flag {
  transition: 0.1s ease-in;
  img {
    height: 28px;
    border: 1px solid #ccc;
    border-radius: 100px;
  }
  &:hover {
    transform: scale(1.1, 1.1);
  }
}
.highlight-lang {
  background: #74B339;
  padding: 6px 10px;
  color: white;
  border-radius: 6px;
}
</style>
