<template>
  <div class="customer flex-centered">
    <div class="background"></div>
    <div
      class="content-area py-xl flex column justify-evenly text-light title-md"
    >
      <div class="text-area pl-xl ml-xl">
        <div class="title bold poppins">{{ $t("Home.IdeaToProduct") }}</div>
        <p class="description">
          {{ $t("Home.IdeaToProductDescription") }}
        </p>
        <div class="title-xxl">99%</div>
        <div class="description mt-lg title-lg mb-sm">
          {{ $t("Home.CustomerSatisfaction") }}
        </div>
        <div class="description">
          {{ $t("Home.CustomerSatisfactionDescription") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "IdeaIntoProduct"
};
</script>
<style lang="scss">
.customer {
  height: 500px;
  .background {
    height: 70%;
    width: 30%;
    background: url("https://images.unsplash.com/photo-1583550267812-bcf638444331?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1489&q=80");
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: #7cbc44c9;
      height: 100%;
      width: 100%;
      z-index: 0;
    }
  }
  .content-area {
    box-sizing: border-box;
    background: url("https://images.unsplash.com/photo-1583550267812-bcf638444331?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1489&q=80");
    height: 100%;
    position: relative;
    box-shadow: -4px 0 24px rgba(0, 0, 0, 0.5);
    &::before {
      content: "";
      position: absolute;
      left: 0;
      background: rgba(0, 0, 0, 0.76);
      height: 100%;
      width: 100%;
      z-index: 0;
    }
    .text-area {
      box-sizing: border-box;
      width: 70%;
    }
  }
}
@media (max-width: 1200px) {
  .customer {
    margin: 6vh 0 4vh 0;
    height: unset;
    .background {
      display: none;
    }
  }
}
</style>
