<template>
  <div class="testimonial wrapper-y wrapper-x flex-centered column wr">
    <div class="content text-center">
      <div class="full-width text-left">
        <img
          src="../../assets/images/quoteMark.png"
          alt=""
          class="quote-mark"
        />
      </div>
      <div class="title-lg mt-md">
        {{ $t("Home.ChadStewartDescription") }}
      </div>
      <div class="user-info flex-centered column">
        <img src="../../assets/images/chad.png" alt="" class="user-pic mt-lg" />
        <div class="testo-info">
          <div class="title-md bold mt-md">{{ $t("Home.ChadStewart") }}</div>
          <div class="title-md">{{ $t("Home.ChadStewartTitle") }}</div>
          <img
            src="../../assets/images/flowGray.png"
            class="mt-md flow-gray"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Testomonial"
};
</script>
<style lang="scss">
.testimonial {
  background: url("../../assets/images/wavyBg.svg") center no-repeat;
  background-size: contain;
  .full-width img {
    height: 80px;
    width: auto;
  }
  .flow-gray {
    height: 50px;
    width: auto;
  }
  .user-pic {
    height: 200px;
    width: 200px;
  }
}
@media (max-width: 1200px) {
  .testimonial {
    .content {
      .full-width {
        img.quote-mark {
          height: 40px;
          width: 40px;
        }
      }
      .user-info {
        align-items: center;
        img {
          height: 110px;
          width: 110px;
        }
        flex-direction: column;
        .flow-gray {
          height: 30px;
          width: auto;
        }
      }
    }
  }
}
</style>
