const initialState = {
  // locale: localStorage.getItem("locale") || "ja"
  locale: "ja"
};

export default {
  state: initialState,
  mutations: {
    setLocale(state, { locale }) {
      localStorage.setItem("locale", locale);
      state.locale = locale;
    }
  },
  getters: {
    getLocale: state => {
      return state.locale ? state.locale : navigator.language;
    }
  },
  actions: {}
};
