<template>
  <div class="idea flex-centered column wrapper-x pb-xl mb-xl">
    <div class="text-area">
      <div v-if="$i18n.locale === 'en'" class="title text-center poppins">
        Experience
        <b class="high-light">world-class culture</b> of business applications
        development.
      </div>
      <div v-if="$i18n.locale === 'ja'" class="title text-center poppins">
        <b class="high-light">世界</b
        >トップクラスのビジネスアプリケーション開発を是非お試しください。
      </div>
      <div class="sub-title text-center py-md half-w">
        {{ $t("Home.WorldClassCultureDescription") }}
      </div>

      <div class="flex-centered pb-xl">
        <router-link :to="{ path: '/contact' }">
          <button class="btn secondary">
            {{ $t("Home.GetQuote") }}
          </button></router-link
        >
      </div>
    </div>
    <div class="boxes flex py-md">
      <div
        class="idea-box flex-centered column text-center pa-md text-light"
        :style="{ backgroundImage: `url('${getImgUrl(idea.bg)}')` }"
        v-for="(idea, i) in ideas"
        :key="i"
      >
        <div class="title-lg idea-title bold">
          {{ idea.title }}
        </div>
        <p class="idea-description">
          {{ idea.description }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "IdeaServices",
  data() {
    return {
      ideas: [
        {
          bg: "product1",
          title: this.$t("Home.DataAnalytics"),
          description: this.$t("Home.DataAnalyticsDescription")
        },
        {
          bg: "product2",
          title: this.$t("Home.Intelligence"),
          description: this.$t("Home.IntelligenceDescription")
        },
        {
          bg: "product3",
          title: this.$t("Home.ProductStartUp"),
          description: this.$t("Home.ProductStartUpDescription")
        }
      ]
    };
  },
  created() {
    console.log(this.$i18n.locale, "language");
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/images/" + pic + ".png");
    }
  }
};
</script>
<style lang="scss" scoped>
.idea-title {
  font-size: 24px;
}
.idea-description {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6rem;
  width: 60%;
}
.idea-box {
  height: 350px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1, 1.1);
  }

  &:nth-child(2) {
    margin: 0 32px;
  }
}
@media (max-width: 1200px) {
  .high-light {
    display: none !important;
  }
  .idea-box {
    margin-bottom: 8px;

    width: 100%;
    background-position: center;
    background-size: cover;

    &:nth-child(2) {
      margin: 0 0 8px;
    }
  }
  .boxes {
    flex-direction: column;
    .box {
      width: 100%;
      height: 150px;
      margin-bottom: 16px;
      .goal {
        flex-direction: row;
        img {
          width: 40px;
          height: 40px;
          object-position: center;
          object-fit: contain;
        }
        .info-area {
          padding-left: 24px;
          .title-md {
            text-align: left;
          }
          p {
            text-align: left;
            font-size: 0.8rem;
          }
        }
      }
      &::before {
        content: unset;
      }
      &:nth-child(2) {
        margin: 0 0 16px;
      }
    }
  }
}
</style>
