<template>
  <div class="goals flex-centered column my-xl py-xl wrapper-x">
    <div class="text-area">
      <div class="title text-center poppins">
        <span class="high-light">{{ $t("Home.WhyChooseUs") }}</span>
      </div>
      <div class="sub-title text-center py-md">
        {{ $t("Home.ChooseDescription") }}
      </div>
    </div>
    <div class="boxes flex py-md">
      <div class="box" v-for="(goal, i) in goals" :key="i">
        <div
          class="bg-dark goal goal-card full-height full-width relative flex-centered column pa-md"
        >
          <img :src="getImgUrl(goal.img)" alt="" />
          <div class="info-area mt-lg text-light">
            <div class="goal-title bold text-center">{{ goal.name }}</div>
            <p class="goal-description text-center">{{ goal.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Goals",
  data() {
    return {
      goals: [
        {
          img: "safeCase",
          name: this.$t("Home.GetMoreForLess"),
          description: this.$t("Home.GetMoreDescription")
        },
        {
          img: "briefcase",
          name: this.$t("Home.99ClientSatisfaction"),
          description: this.$t("Home.99Description")
        },
        {
          img: "trophy",
          name: this.$t("Home.TechnologyTrends"),
          description: this.$t("Home.TechnologyTrendsDescription")
        }
      ]
    };
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/images/" + pic + ".png");
    }
  }
};
</script>
<style lang="scss" scoped>
.goal-title {
  font-size: 24px;
}
.goal-description {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6rem;
}
@media (max-width: 1200px) {
  .boxes {
    flex-direction: column;
    .box {
      width: 100%;
      height: 220px;
      margin-bottom: 16px;
      .goal {
        flex-direction: column;
        img {
          width: 40px;
          height: 40px;
          object-position: center;
          object-fit: contain;
        }
        .info-area {
          .title-md {
            text-align: left;
          }
          p {
            text-align: center;
            font-size: 0.95rem;
          }
        }
      }
      &::before {
        content: unset;
      }
      &:nth-child(2) {
        margin: 0 0 16px;
      }
    }
  }
}
</style>
