<template>
  <div
    class="get-in-touch flex-centered column text-light relative poppins bold"
  >
    <div class="content text-center relative">
      <div class="title-xl">
        {{ $t("Home.TrustedByGlobal") }}
      </div>
      <div class="title-xl my-lg">{{ $t("Home.LetsTalk") }}</div>
      <router-link :to="{ path: '/contact' }">
        <button class="btn secondary">{{ $t("Home.GetInTouch") }}</button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "GetInTouch"
};
</script>
<style lang="scss">
.get-in-touch {
  height: 500px;
  background: url("../../assets/images/team_photo.jpg") center;
  background-size: cover;
  &::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
  }
}
@media (max-width: 1200px) {
  .get-in-touch {
    height: 220px;
    background: url("../../assets/images/team_photo.jpg") center;
    background-size: cover;
    .content {
      padding: 0px 18px;
      .title-xl {
        font-size: 18px;
        font-weight: 500;
      }
      .btn {
        font-size: 14px;
      }
    }
  }
}
</style>
