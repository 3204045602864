<template>
  <div class="footer-top">
    <div class="top-footer flex justify-between wrapper-x pb-lg">
      <div class="flex-4 pb-lg pt-lg pr-lg">
        <div class="title-lg mb-sm bold">{{ $t("Footer.About") }}</div>
        <div class="about-desc description mb-md half-width">
          {{ $t("Footer.AboutDescription") }}
        </div>
      </div>
      <div class="flex-3 pb-lg pt-lg pr-lg">
        <div class="title-lg mb-sm bold">{{ $t("ContactUs.ContactUs") }}</div>
        <div class="description mb-md">{{ $t("Footer.GritFeatJapan") }}</div>
        <div class="description mb-md">{{ $t("Footer.Address1") }}</div>
        <div class="description mb-md">
          {{ $t("Footer.Address2") }} <br />{{ $t("Footer.Address3") }}
        </div>
        <!-- <div class="description">{{ $t("Footer.Phone") }}</div> -->
      </div>
      <div class="flex-3 pb-lg pt-lg">
        <div class="title-lg mb-sm bold">{{ $t("Footer.Hours") }}</div>
        <div class="description mb-md">{{ $t("Footer.Weekdays") }}</div>
        <div class="description mb-md">{{ $t("Footer.WorkingHours") }}</div>
      </div>
      <div class="flex-2 pb-lg pt-lg">
        <div class="title-lg mb-sm bold">{{ $t("Footer.SocialMedia") }}</div>

        <div class="description mb-md social-media">
          <div class="effect lavinia">
            <div class="buttons flex">
              <a
                href="https://www.facebook.com/gritfeat/"
                class="fb"
                title="Join us on Facebook"
              >
                <img src="../../assets/images/Facebook.svg" />
              </a>
              <!-- <a href="#" class="insta" title="Join us on Instagram">
                <img src="../../assets/images/Instagram.png" />
              </a> -->
              <a
                href="https://www.linkedin.com/company/gritfeat-solutions/mycompany/"
                class="in"
                title="Join us on Linked In"
              >
                <img src="../../assets/images/Linkedin.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="flex text-light">
      {{ $t("Footer.CopyRight") }}
    </footer>
  </div>
</template>

<script>
export default {
  components: {},
  data: function() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
footer {
  padding: 1.2vw 7.5vw;
  background: #7CBC44;
}
.social-media img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: contain;
}
.top-footer {
  padding-top: 2vh;
  .description {
    color: #909090 !important;
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  .about-desc {
    width: 100%;
  }
  .top-footer {
    flex-direction: column;
  }
}

$basic-dark-color: #212121;
$basic-light-color: #fff;
$border-radius: 50%;
$font-size: 25px;

.effect {
  display: flex;

  a {
    text-decoration: none !important;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    margin-right: 20px;
    font-size: $font-size;
    overflow: hidden;
    position: relative;
    color: $basic-dark-color; //or change to your own color
    border: 2px solid $basic-dark-color; //or change to your own color

    i {
      position: relative;
      z-index: 3;
    }

    &:last-child {
      margin-right: 0px;
    }

    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    i {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.effect.lavinia {
  a {
    transition: border-top-color 0.2s linear 0s,
      border-right-color 0.2s linear 0.1s, border-bottom-color 0.2s linear 0.2s,
      border-left-color 0.2s linear 0.3s;
    overflow: visible;

    &:hover {
      border-color: rgba($basic-light-color, 0);
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      top: -2px;
      left: -2px;
      border: 2px dashed $basic-light-color;
      position: absolute;
      border-radius: $border-radius;
    }
  }
}
</style>
