<template>
  <div class="home">
    <div class="banner flex-centered">
      <img src="../assets/images/banner-bg.jpg" alt="" class="banner-img" />
      <div class="text-area flex-centered column text-light">
        <div class="title half-width text-center pt-xl pb-md">
          {{ $t("Landing.BannerText") }}
        </div>
        <router-link :to="{ path: '/contact' }">
          <button class="btn secondary">{{ $t("Home.GetInTouch") }}</button>
        </router-link>
      </div>
    </div>

    <div class="flex-centered">
      <div class="box-behind"></div>
    </div>

    <main class="flex column justify-center">
      <Goals />
      <IdeaServices />
      <IdeaIntoProduct />
      <Testimonial />
      <GetInTouch />
    </main>
    <Footer />
  </div>
</template>

<script>
import Goals from "@/components/Home/Goals";
import IdeaServices from "@/components/Home/IdeaServices";
import IdeaIntoProduct from "@/components/Home/IdeaIntoProduct";
import Testimonial from "@/components/Home/Testimonial";
import GetInTouch from "@/components/Common/GetIntouch";
import Footer from "@/components/Common/Footer";
export default {
  name: "Home",
  components: {
    Footer,
    GetInTouch,
    Testimonial,
    IdeaIntoProduct,
    IdeaServices,
    Goals
  }
};
</script>

<style lang="scss">
.banner {
  box-sizing: border-box;
  height: 600px;
  //background: url("../assets/images/banner-bg.jpg") center;
  //background-size: cover;
  position: relative;
  .banner-img {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    &::before {
      content: "";
      height: 100%;
      top: 0;
      left: 0;
      width: 100%;
      background: radial-gradient(
        60.3% 60.3% at 50% 50%,
        rgba(0, 0, 0, 0.76) 0%,
        rgba(17, 3, 3, 0.38) 100%
      );
      position: absolute;
      z-index: 1;
    }
  }
  .text-area {
    z-index: 44;
  }
  &::before {
    content: "";
    height: 100%;
    width: 100%;
    background: radial-gradient(
      60.3% 60.3% at 50% 50%,
      rgba(0, 0, 0, 0.76) 0%,
      rgba(17, 3, 3, 0.38) 100%
    );
    position: absolute;
    z-index: 1;
  }
  &.about-cover {
    //background: url("../assets/images/about-cover.png");
  }
  &.services-cover {
    //background: url("../assets/images/services-cover.jpg");
  }
}

.box {
  height: 430px;
  width: 350px;

  transition: 0.5s all;
  position: relative;
  .goal {
    background: #1f1f1f;
    border-radius: 8px;
  }
  &::before {
    height: 430px;
    width: 350px;
    transform: translate(-20px, 20px);
    border: 4px solid #7CBC44;
    border-radius: 8px;
    position: absolute;
    content: "";
    opacity: 0;
    z-index: -1;
    transition: 0.8s all;
  }
  &:hover {
    transform: translateY(4px);
    &::before {
      opacity: 100;
    }
  }
  &:nth-child(2) {
    margin: 0 32px;
  }
}
.box-behind {
  height: 30px;
  background: #7CBC44;
  padding: 0 7.5vw;
  width: 60%;
}
.text-area {
  z-index: 1;
}
.home {
  height: 90vh;
}
@media (max-width: 1200px) {
  .text-area {
    .title {
      font-size: 28px;
    }
  }
  .btn {
    font-size: 16px;
  }
}
</style>
